<template>
  <div id="app">
    <transition name="fade">
      <loader-component v-if="showLoader"></loader-component>
    </transition>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import LoaderComponent from '@/components/LoaderComponent';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    LoaderComponent,
  },
  methods: {
    ...mapActions(['updateShowLoader']),
  },
  computed: {
    ...mapGetters(['showLoader']),
  },
  watch: {
    $route({ name }) {
      const viwesWithLoader = [
        'Manage',
        'Social',
        'Invoices',
        'ChechActive',
        'Settings',
      ];
      if (viwesWithLoader.includes(name)) {
        this.updateShowLoader(true);
      }
    },
  },
  mounted() {
    const lang = localStorage.getItem('lang');
    if (lang === null) {
      localStorage.setItem('lang', 'gb');
    }
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  margin: 0 auto;
}
</style>
