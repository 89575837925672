const user = {
  state: () => ({
    userData: JSON.parse(localStorage.getItem('userData')) || {},
    isUserLoggedIn: localStorage.getItem('isUserLoggedIn') || false,
  }),
  getters: {
    userData(state) {
      return state.userData;
    },
    isUserLoggedIn(state) {
      return state.isUserLoggedIn;
    },
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setUserLoggedIn(state, payload) {
      state.isUserLoggedIn = payload;
    },
  },
  actions: {
    updateUserData(context, payload) {
      localStorage.setItem('userData', JSON.stringify(payload));
      context.commit('setUserData', payload);
    },
    updateUserLoggedIn(context, payload) {
      localStorage.setItem('isUserLoggedIn', payload);
      context.commit('setUserLoggedIn', payload);
    },
  },
};

export default user;
