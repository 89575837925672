const loader = {
  state: () => ({
    showLoader: false,
  }),
  getters: {
    showLoader(state) {
      return state.showLoader;
    },
  },
  mutations: {
    setShowLoader(state, payload) {
      state.showLoader = payload;
    },
  },
  actions: {
    updateShowLoader(context, payload) {
      context.commit('setShowLoader', payload);
    },
  },
};

export default loader;
