<template>
  <div class="loader-mask">
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent',
};
</script>

<style lang="scss" scoped src="./LoaderComponent.scss"></style>
