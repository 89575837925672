import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import userModule from './modules/user';
import loaderModule from './modules/loader';

export default new Vuex.Store({
  modules: {
    userModule,
    loaderModule,
  },
});
