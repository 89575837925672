import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/plugins/i18n';
import store from '../store';

Vue.use(VueRouter);

const defaultHeader = i18n.t('xbody');

const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/WorkspaceView'),
    children: [
      {
        path: 'social-accounts',
        name: 'Social',
        meta: {
          title: `${defaultHeader} || ${i18n.t('joinSocialAccounts')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/SocialAccountsView'),
      },
      {
        path: '',
        name: 'Home',
        meta: {
          title: `${defaultHeader} || ${i18n.t('home')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/HomeView'),
      },
      {
        path: 'manage',
        name: 'Manage',
        meta: {
          title: `${defaultHeader} || ${i18n.t('manageConsents')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/ManageView'),
      },
      {
        path: 'invoices',
        name: 'Invoices',
        meta: {
          title: `${defaultHeader} || ${i18n.t('invoices')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/InvoicesView'),
      },
      {
        path: 'check-active',
        name: 'ChechActive',
        meta: {
          title: `${defaultHeader} || ${i18n.t('checkActiveApplications')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/ChechActiveView'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          title: `${defaultHeader} || ${i18n.t('settings')}`,
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: 'about' */ '@/views/SettingView'),
      },
    ],
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: {
      title: `${defaultHeader} || ${i18n.t('registration')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/RegistaionView'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: `${defaultHeader} || ${i18n.t('login')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/LoginView'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      title: `${defaultHeader} || ${i18n.t('resetPassword')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/ResetPasswordView'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      title: `${defaultHeader} || ${i18n.t('forgotPassword')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/ForgotPasswordView'),
  },
  {
    path: '/confirm-email',
    name: 'ComfirmEmail',
    meta: {
      title: `${defaultHeader} || ${i18n.t('confirmEmail')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/ComfirmEmailView'),
  },
  {
    path: '/reset-confirm',
    name: 'ResetComfirm',
    meta: {
      title: `${defaultHeader} || ${i18n.t('confirmReset')}`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/ResetComfirmView'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: `privacy-policy`,
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/PolicyView'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || defaultHeader;
  if (
    (to.meta?.requiresAuth && !store.getters.isUserLoggedIn) ||
    (from.meta.requiresAuth && !store.getters.isUserLoggedIn)
  ) {
    location.replace('/login');
    return;
  } else {
    if (to.meta?.requiresAuth == false && store.getters.isUserLoggedIn) {
      location.replace('/');
      return;
    }
    next();
  }
});

export default router;
